.App {
  background-color: #d60149;
  padding: 1rem 2rem 1rem 2rem;
  height: calc(100vh - 2rem);
}

h1 {
  color: #f3e8cc;
  margin-top: 0;
  margin-bottom: 1rem;
}
