.note.new {
  background-color: #3e801a;
}
textarea {
  border: none;
  background-color: transparent;
  resize: none;
  outline: none;
  color: #f3e8cc;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 14px;
}
::placeholder {
  color: #f3e8cc;
}
.save {
  cursor: pointer;
}
